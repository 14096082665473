import React from 'react'
import { graphql, Link } from 'gatsby'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import SimpleReactLightbox, { SRLWrapper } from 'simple-react-lightbox'
import { Header, Footer } from '../components/Common'
import { PageSection, BannerImage } from '../components/Layout'
import { theme } from '../styles'
import '../styles/global.css'

const GalleryPage = ({ data }) => {
  const pageData = data.allPrismicGalleryPage.nodes[0].data
  const commonData = data.allPrismicCommonPageContent.nodes[0].data
  const jobs = data.allPrismicJob.nodes
  const images = jobs.flatMap((j) => j.data.images)

  return (
    <GalleryPageWrapper>
      <Header logoUrl={commonData.logo_image.url} />
      <BannerImage
        imageFluid={pageData.banner_image.localFile.childImageSharp.fluid}
        titleHtml={pageData.page_title.html}
      />
      <PageSection>
        <SimpleReactLightbox>
          <SRLWrapper>
            <GalleryGrid>
              {images.map((image) => (
                <Anchor to={image.job_image.url}>
                  <Img
                    key={image.job_image.url}
                    fixed={image.job_image.localFile.childImageSharp.fixed}
                  />
                </Anchor>
              ))}
            </GalleryGrid>
          </SRLWrapper>
        </SimpleReactLightbox>
      </PageSection>
      <Footer data={commonData.footer_text_content.html} />
    </GalleryPageWrapper>
  )
}

const GalleryPageWrapper = styled.div``

const Anchor = styled(Link)`
  height: 200px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);

  &:hover {
    filter: brightness(120%);
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    margin-top: -3px;
    margin-bottom: 3px;
    cursor: pointer;
  }
`

const GalleryGrid = styled.div`
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(5, 200px);
  justify-items: center;
  column-gap: 15px;
  row-gap: 15px;

  @media (max-width: ${theme.breakpoints.l}) {
    grid-template-columns: repeat(4, 200px);
  }

  @media (max-width: ${theme.breakpoints.m}) {
    grid-template-columns: repeat(3, 200px);
  }

  @media (max-width: ${theme.breakpoints.s}) {
    grid-template-columns: repeat(2, 200px);
  }

  @media (max-width: ${theme.breakpoints.xs}) {
    grid-template-columns: repeat(1, 200px);
  }
`

export const query = graphql`
  query GalleryPageQuery {
    allPrismicJob {
      nodes {
        data {
          images {
            job_image {
              alt
              url
              localFile {
                childImageSharp {
                  fixed(width: 200, height: 200, quality: 80) {
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            }
          }
        }
      }
    }
    allPrismicCommonPageContent {
      nodes {
        data {
          footer_text_content {
            html
          }
          logo_image {
            url
          }
        }
      }
    }
    allPrismicGalleryPage {
      nodes {
        data {
          page_title {
            html
          }
          banner_image {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1920, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`

GalleryPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default GalleryPage
